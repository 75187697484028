import React from 'react';
import ComparisonTableDesktop from '../styled/ComparisonTableDesktop';
import logo from 'images/logo-tastyworks-black.svg';

function DesktopTable() {
  return (
    <>
      <ComparisonTableDesktop>
        <thead>
          <tr>
            <th />
            <th>
              Commission US Shares<sup>*</sup>
            </th>
            <th>
              Ongoing Account Fees<sup>**</sup>
            </th>
          </tr>
          <tr>
            <th />
            <th className='dollar-disclaimer' colspan='2'>
              Prices are shown in USD
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className='tasty'>
            <td className='first-shares-cell'>
              <img src={logo} alt='tastylive' />
            </td>
            <td>$0.00</td>
            <td>None</td>
          </tr>
          <tr>
            <td className='first-shares-cell'>CommSec (US Markets)</td>
            <td>$19.95 for trades up to $5,000 $29.95 for trades up to $10,000 0.31% for trades above $10,000</td>
            <td>$25.00 yearly* + AUD $82.50 monthly CommSecIRESS Platform<sup>3</sup> + AUD $11.00 monthly live streaming data<sup>4</sup></td>
          </tr>
          <tr>
            <td className='first-shares-cell'>Interactive Brokers (US Markets)</td>
            <td>$1.00<sup>5</sup></td>
            <td>$10.00<sup>1</sup> + $4.50 monthly live streaming data</td>
          </tr>
          <tr>
            <td className='first-shares-cell'>SAXO Markets (US Markets)</td>
            <td>$9.90</td>
            <td>0.12% monthly<sup>*</sup> + $7.00 monthly live streaming data</td>
          </tr>
          <tr>
            <td className='first-shares-cell'>SelfWealth (US Markets)</td>
            <td>$9.50</td>
            <td>AUD $20.00 monthly<sup>2</sup></td>
          </tr>
          <tr>
            <td className='first-shares-cell'>Westpac (US Markets)</td>
            <td>$19.95 for trades up to $5,000 $29.95 for trades up to $10,000 0.31% for trades above $10,000</td>
            <td>$63.50 yearly<sup>*</sup></td>
          </tr>
        </tbody>
      </ComparisonTableDesktop>
      <ComparisonTableDesktop $tableType='shares'>
        <thead>
          <tr>
            <th />
            <th className='dollar-disclaimer' colspan='2'>
              Prices are shown in AUD
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className='first-shares-cell'>ANZ (Global Markets)</td>
            <td>$59.00 for trades up to $10,000 0.59% for trades above $10,000</td>
            <td>0.50% monthly<sup>*</sup></td>
          </tr>
          <tr>
            <td className='first-shares-cell'>Nabtrade (Global Markets)</td>
            <td>$14.95 for trades up to $5,000 $19.95 for trades up to $20,000 0.11% for trades above $20,000</td>
            <td>0.50% monthly<sup>*</sup></td>
          </tr>
          <tr>
            <td className='first-shares-cell'>St. George Bank (Global Markets)</td>
            <td>$59.00 for trades up to $10,000 0.59% for trades above $10,000</td>
            <td>0.50% monthly<sup>*</sup></td>
          </tr>
        </tbody>
      </ComparisonTableDesktop>
    </>
  );
}

export default DesktopTable;
