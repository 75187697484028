import React from 'react';
import styled from 'styled-components';
import ComparisonRow from 'components/ComparisonRow';
import ComparisonTableMobile from '../styled/ComparisonTableMobile';
import {
  CompareMobileHeader,
  CompareMobileSection,
  DollarDisclaimer
} from '../styled/CompareMobileShared';

const SharesData = styled.div`
  font-size: 0.9375rem;
  font-weight: 400;
`;

function MobileTable() {
  return (
    <ComparisonTableMobile>
      <CompareMobileSection>
        <CompareMobileHeader>
          <p className='font-weight-bold'>Commission US Shares<sup>*</sup></p>
          <DollarDisclaimer>Prices are shown in USD</DollarDisclaimer>
        </CompareMobileHeader>
        <ComparisonRow
          firstRow
          name='tastytrade'
          data='$0.00'
          tableType='shares'
        />
        <ComparisonRow
          name={<>Commsec <br /> (US Markets)</>}
          data={<SharesData>$19.95 for trades up to $5,000 <br /> $29.95 for trades up to $10,000 <br /> 0.31% for trades above $10,000</SharesData>}
          tableType='shares'
        />
        <ComparisonRow
          name={<>Interactive Brokers <br /> (US Markets)</>}
          data={<SharesData>$1.00<sup>5</sup></SharesData>}
          tableType='shares'
        />
        <ComparisonRow
          name={<>Saxo Markets <br /> (US Markets)</>}
          data={<SharesData>$9.90</SharesData>}
          tableType='shares'
        />
        <ComparisonRow
          name={<>SelfWealth <br /> (US Markets)</>}
          data={<SharesData>$9.50</SharesData>}
          tableType='shares'
        />
        <ComparisonRow
          name={<>Westpac <br /> (US Markets)</>}
          data={<SharesData>$19.95 for trades up to $5,000 <br /> $29.95 for trades up to $10,000 <br /> 0.31% for trades above $10,000</SharesData>}
          tableType='shares'
        />

        <CompareMobileHeader $australia>
          <DollarDisclaimer>Prices are shown in AUD</DollarDisclaimer>
          <ComparisonRow
            name={<>ANZ <br /> (Global Markets)</>}
            data={<SharesData>$59.00 for trades up to $10,000 <br /> 0.59% for trades above $10,000</SharesData>}
            tableType='shares'
          />
          <ComparisonRow
            name={<>Nabtrade <br /> (Global Markets)</>}
            data={<SharesData>$14.95 for trades up to $5,000 <br /> $19.95 for trades up to $20,000 <br/> 0.11% for trades above $20,000</SharesData>}
            tableType='shares'
          />
          <ComparisonRow
            name={<>St. George Bank <br /> (Global Markets)</>}
            data={<SharesData>$59.00 for trades up to $10,000 <br /> 0.59% for trades above $10,000</SharesData>}
            tableType='shares'
          />
        </CompareMobileHeader>
      </CompareMobileSection>
      <CompareMobileSection>
        <CompareMobileHeader>
          <p className='font-weight-bold'>Ongoing Account Fees<sup>**</sup></p>
          <DollarDisclaimer>Prices are shown in USD</DollarDisclaimer>
        </CompareMobileHeader>
        <ComparisonRow
          firstRow
          name='tastytrade'
          data='None'
          tableType='shares'
        />
        <ComparisonRow
          name={<>Commsec <br /> (US Markets)</>}
          data={<SharesData>$25.00 yearly* + AUD $82.50 monthly CommSecIRESS Platform<sup>3</sup> + AUD $11.00 monthly live streaming data<sup>4</sup></SharesData>}
          tableType='shares'
        />
        <ComparisonRow
          name={<>Interactive Brokers <br /> (US Markets)</>}
          data={<SharesData>$10.00<sup>1</sup> + $4.50 monthly live streaming data</SharesData>}
          tableType='shares'
        />
        <ComparisonRow
          name={<>Saxo Markets <br /> (US Markets)</>}
          data={<SharesData>0.12% monthly<sup>*</sup> + $7.00 monthly <br /> live streaming data</SharesData>}
          tableType='shares'
        />
        <ComparisonRow
          name={<>SelfWealth <br /> (US Markets)</>}
          data={<SharesData>AUD $20.00 monthly<sup>2</sup></SharesData>}
          tableType='shares'
        />
        <ComparisonRow
          name={<>Westpac <br /> (US Markets)</>}
          data={<SharesData>$63.50 yearly<sup>*</sup></SharesData>}
          tableType='shares'
        />

        <CompareMobileHeader $australia>
          <DollarDisclaimer>Prices are shown in AUD</DollarDisclaimer>
          <ComparisonRow
            name={<>ANZ <br /> (Global Markets)</>}
            data={<SharesData>0.50% monthly<sup>*</sup></SharesData>}
            tableType='shares'
          />
          <ComparisonRow
            name={<>Nabtrade <br /> (Global Markets)</>}
            data={<SharesData>0.50% monthly<sup>*</sup></SharesData>}
            tableType='shares'
          />
          <ComparisonRow
            name={<>St. George Bank <br /> (Global Markets)</>}
            data={<SharesData>0.50% monthly<sup>*</sup></SharesData>}
            tableType='shares'
          />
        </CompareMobileHeader>
      </CompareMobileSection>
    </ComparisonTableMobile>
  );
}

export default MobileTable;
