import styled from 'styled-components';
import { prop } from 'styled-tools';

const ComparisonTableMobile = styled.div`
  display: block;
  padding-bottom: 1.875rem;

  @media (min-width: ${prop('theme.breakpoints.md')}) {
    display: none;
  }
`;

export default ComparisonTableMobile;
