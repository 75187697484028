import React from 'react';
import { Link } from 'gatsby';
import { prop } from 'styled-tools';
import styled from 'styled-components';
import SmallPrint from 'components/SmallPrint';

export const FootnoteLink = styled(Link)`
  &&& {
    color: ${prop('theme.colors.primaryRed')};
    font-weight: 700;
    text-decoration: none;

    &:hover {
      color: ${prop('theme.colors.gray')};
      text-decoration: none;
    }
  }
`;

function Disclaimers() {
  return (
    <>
      <SmallPrint>
        <sup>1</sup> Regulatory fees still apply. All futures options and the following index
        products are excluded from this offer: SPX, RUT, VIX, OEX, XEO, DJX and NDX. Certain
        complex options strategies carry additional risk.
      </SmallPrint>
      <SmallPrint>
        <sup>2</sup> Commission comparison based on opening and closing online U.S. equity
        option trades at the published website commission schedules for retail accounts, as
        of 3 June 2021, for E*Trade, Interactive Brokers, Saxo Markets and Schwab. For Interactive Brokers,
        comparison based on customers trading less than 10,000 contracts a month with option premiums equal to or
        greater than .10 per contract. For Saxo Markets, pricing is based upon the “Classic” account tier trading on SaxoTradingGo platform.
        Supporting documentation for any claims, if applicable, will be furnished upon request.
      </SmallPrint>
      <SmallPrint>
        <sup>3</sup> Commission comparison based on opening and closing online ASX equity option trades at the
        published website commission schedules for retail accounts, as of 3 June 2021, for Commsec.
      </SmallPrint>
      <SmallPrint>
        <sup>4</sup> Exchange Traded Options traded online, (also charged upon exercise or assignment of the option).
      </SmallPrint>
      <SmallPrint>
        <sup>5</sup> Exchange Traded Options traded over the phone, (also charged upon exercise or assignment of the option).
      </SmallPrint>
      <SmallPrint>
        <sup>6</sup> Commsec Exchange Traded Options contract fees (per contract): <br/>
        $0.13 (GST exclusive) Equity Trades<br/> $0.05 (GST exclusive) Equity Exercise/Assignment<br/>
        $0.45 (GST exclusive) Index Trades<br/> $0.35 (GST exclusive) Index Exercise/Assignment
      </SmallPrint>
      <SmallPrint>
        <sup>7</sup> Commission comparison based on opening and closing online ASX equity option trades
        at the published website commission schedules for retail accounts, as of 3 June 2021, for Westpac.
      </SmallPrint>
      <SmallPrint>
        <sup>8</sup> Westpac Exchange Traded Options contract fees (per contract): <br/>
        $0.13 (GST exclusive) Equity Trades<br/> $0.05 (GST exclusive) Equity Exercise/Assignment<br/>
        $0.45 (GST exclusive) Index Trades<br/> $0.35 (GST exclusive) Index Exercise/Assignment
      </SmallPrint>
    </>
  );
}

export default Disclaimers;
