import React from 'react';
import MobileTable from './MobileTable';
import DesktopTable from './DesktopTable';
import SmallPrint from 'components/SmallPrint';
import logo from 'images/logo-tastyworks-black.svg';

function SharesTable() {
  return (
    <>
      <DesktopTable />
      <MobileTable />
      <SmallPrint>
        Figures above based on trading less than 3 trades per quarter, average trade
        size $8,000 with account funded up to $250,000. tastytrade commission is always $0 regardless of the
        number of trades, trade size, or account size.
      </SmallPrint>
      <SmallPrint>
        <sup>*</sup> All stock trades incur a clearing fee of $0.0008 per share.
      </SmallPrint>
      <SmallPrint>
        <sup>**</sup> Custody fee calculated daily and charged monthly. NABtrade: Yearly fee.
        Calculated on average daily international holding balance, payable monthly.
        No trades per year. Saxo: Yearly Custody fee calculated daily and charged monthly.
        ANZ: Yearly Custody fee calculated daily and charged monthly, minimum $10. Westpac: Yearly fee.
        Accounts holding a security position for a calendar year (1 January to 31 December) without generating a trade,
        or margin interest of US$100 or more. St. George Bank: Yearly Custody fee calculated daily and charged monthly, minimum $10. CommSec:
        Yearly fee. Accounts holding a security position for a calendar year (1 January to 31 December) without generating a trade, or margin interest of US$100 or more.
        Data is correct as of 3 June 2021.
      </SmallPrint>
      <SmallPrint>
        <sup>1</sup> If monthly commissions are less than US$10, standard activity fee = USD $10.00 - commissions.
      </SmallPrint>
      <SmallPrint>
        <sup>2</sup> SelfWealth Premium Membership required for live streaming data charged at AUD $20.00 monthly.
      </SmallPrint>
      <SmallPrint>
        <sup>3</sup> CommSecIRESS Subscription Fee, charged in arrears for the previous calendar month. $82.50 per month -
        or free if you place at least 8 eligible trades in a month, or spend $220 in brokerage.
      </SmallPrint>
      <SmallPrint>
        <sup>4</sup> Live data feed of closing prices for international market indices. $11.00 per month in
        addition to the CommSecIRESS Subscription Fee.
      </SmallPrint>
      <SmallPrint>
        <sup>5</sup> $1.00 minimum charge is charged to open, and then charged to close,
        based on Interactive Brokers ‘Fixed’ choice of rates.
      </SmallPrint>
    </>
  );
}

export default SharesTable;
