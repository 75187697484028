import { ifProp, prop } from 'styled-tools';
import styled, { css } from 'styled-components';

export const CompareMobileSection = styled.div`
  margin-bottom: 1.25rem;
`;

export const CompareMobileHeader = styled.div`
  margin-bottom: 0.125rem;
  text-align: center;

  p {
    line-height: 1rem;
    margin-bottom: 0;
  }

  ${ifProp('$australia', css`
    margin-top: 1rem;
  `)}
`;

export const DollarDisclaimer = styled.p`
  font-style: italic;
  margin-top: 0.5rem;

  &&& {
    font-weight: 700;
  }
`;
