import React, { useState } from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import OptionsTable from './OptionsTable';
import SharesTable from './SharesTable';
import TitleHeader from '../TitleHeader';

const ApplesTabs = styled(Nav)`
  &.nav-tabs {
    border: none;
    justify-content: flex-end;
    margin-bottom: 2rem;
  }
`;

const SingleTab = styled(NavItem)`
  border: 1px solid ${prop('theme.colors.primaryBlack')};
  cursor: pointer;
`;

const TabLink = styled(NavLink)`
  text-transform: uppercase;

  &&& {
    &.nav-link {
      border: none;
      border-radius: 0;
      font-weight: 700;
      text-decoration: none;

      &.active {
        background-color: ${prop('theme.colors.primaryBlack')};
        color: ${prop('theme.colors.white')};
      }
    }
  }
`;

function ApplesToApples() {
  const [activeTab, setActiveTab] = useState('options');

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  return (
    <>
      <TitleHeader>Commission Comparison</TitleHeader>
      <p>
        Okay, you want the bottom line. What are the commission costs to
        open and close equity option trades at tastytrade compared to other
        major brokers? Well, have a look…
      </p>
      <ApplesTabs tabs>
        <SingleTab>
          <TabLink
            className={activeTab === 'options' ? 'active' : ''}
            onClick={() => toggleTab('options')}
          >
            Options
          </TabLink>
        </SingleTab>
        <SingleTab>
          <TabLink
            className={activeTab === 'shares' ? 'active' : ''}
            onClick={() => toggleTab('shares')}
          >
            Shares
          </TabLink>
        </SingleTab>
      </ApplesTabs>
      <TabContent activeTab={activeTab}>
        <TabPane tabId='options'>
          <OptionsTable />
        </TabPane>
        <TabPane tabId='shares'>
          <SharesTable />
        </TabPane>
      </TabContent>
    </>
  );
}

export default ApplesToApples;
