import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComparisonRow from 'components/ComparisonRow';
import ComparisonTableMobile from '../styled/ComparisonTableMobile';
import {
  CompareMobileHeader,
  CompareMobileSection,
  DollarDisclaimer
} from '../styled/CompareMobileShared';

const Variance = styled.span`
  display: block;
  font-weight: 700;
`;

const OneLotCommsec = styled.span`
  font-size: 0.9375rem;
`;

const BrokerDollarDisclaimer = styled(DollarDisclaimer)`
  text-align: right;
  padding: 0.625rem 0.625rem 0 0.625rem;
`;

const OptionsData = styled.div`
  font-size: 0.9375rem;
  font-weight: 400;
`;

MobileTable.propTypes = {
  data: PropTypes.object,
  ironCondor: PropTypes.object,
  oneLot: PropTypes.string,
  puts: PropTypes.object,
  tableHeader: PropTypes.string,
  vertical: PropTypes.object,
};

function MobileTable({ data, ironCondor, oneLot, puts, tableHeader, vertical }) {
  return (
    <ComparisonTableMobile>
      <CompareMobileSection>
        <CompareMobileHeader>
          <p className='font-weight-bold'>{get(puts, 'lot')} Puts</p>
          <p>({get(puts, 'contracts')} total contracts)</p>
          <DollarDisclaimer>Prices are shown in USD</DollarDisclaimer>
        </CompareMobileHeader>
        <ComparisonRow
          firstRow
          footnote={1}
          name='tastytrade'
          data={`${'$' + get(puts, 'tastyworks')}`}
          tableType='options'
        />
        <ComparisonRow
          footnote={2}
          name='E*TRADE'
          data={`${'$' + get(puts, 'eTrade')}`}
          tableType='options'
        />
        <ComparisonRow
          footnote={2}
          name='Interactive Brokers'
          data={`${'$' + get(puts, 'interactive')}`}
          tableType='options'
        />
        <ComparisonRow
          footnote={2}
          name='Saxo Markets'
          data={`${'$' + get(puts, 'saxo')}`}
          tableType='options'
        />
        <ComparisonRow
          footnote={2}
          name='Schwab'
          data={`${'$' + get(puts, 'schwab')}`}
          tableType='options'
        />

        <CompareMobileHeader $australia>
          <BrokerDollarDisclaimer>Prices are shown in AUD</BrokerDollarDisclaimer>
        </CompareMobileHeader>
        <ComparisonRow
          commsec={true}
          footnote={3}
          name='Commsec'
          data={<OneLotCommsec>Price per contract start at $34.95 (Up to and including $10,000) <Variance>OR</Variance> 0.35% (above $10,000)<sup>4</sup> + ETO contract fees (per contract)<sup>6</sup></OneLotCommsec>}
          tableType='options'
        />
        <ComparisonRow
          commsec={true}
          footnote={7}
          name='Westpac'
          data={<OneLotCommsec>Price per contract start at $38.95 <Variance>OR</Variance> 0.35%<sup>4</sup> + ETO contract fees (per contract)<sup>8</sup></OneLotCommsec>}
          tableType='options'
        />

      </CompareMobileSection>
      <CompareMobileSection>
        <CompareMobileHeader>
          <p className='font-weight-bold'>{get(vertical, 'lot')} Verticals</p>
          <p>({get(vertical, 'contracts')} total contracts)</p>
          <DollarDisclaimer>Prices are shown in USD</DollarDisclaimer>
        </CompareMobileHeader>
        <ComparisonRow
          firstRow
          footnote={1}
          name='tastytrade'
          data={`${'$' + get(vertical, 'tastyworks')}`}
          tableType='options'
        />
        <ComparisonRow
          footnote={2}
          name='E*TRADE'
          data={`${'$' + get(vertical, 'eTrade')}`}
          tableType='options'
        />
        <ComparisonRow
          footnote={2}
          name='Interactive Brokers'
          data={`${'$' + get(vertical, 'interactive')}`}
          tableType='options'
        />
        <ComparisonRow
          footnote={2}
          name='Saxo Markets'
          data={`${'$' + get(vertical, 'saxo')}`}
          tableType='options'
        />
        <ComparisonRow
          footnote={2}
          name='Schwab'
          data={`${'$' + get(vertical, 'schwab')}`}
          tableType='options'
        />

        <CompareMobileHeader $australia>
          <DollarDisclaimer>Prices are shown in AUD</DollarDisclaimer>
        </CompareMobileHeader>
        <ComparisonRow
          commsec={true}
          footnote={3}
          name='Commsec'
          data={<OneLotCommsec>Price per contract start at $54.60 (Up to and including $10,000) <Variance>OR</Variance> 0.54% (Over $10,000)<sup>5</sup> + ETO contract fees (per contract)<sup>6</sup></OneLotCommsec>}
          tableType='options'
        />
        <ComparisonRow
          commsec={true}
          footnote={7}
          name='Westpac'
          data={<OneLotCommsec>Price per contract start at $59.90 <Variance>OR</Variance> 0.55%<sup>5</sup> + ETO contract fees (per contract)<sup>8</sup></OneLotCommsec>}
          tableType='options'
        />
      </CompareMobileSection>
      <CompareMobileSection>
        <CompareMobileHeader>
          <p className='font-weight-bold'>{get(ironCondor, 'lot')} Iron Condors</p>
          <p>({get(ironCondor, 'contracts')} total contracts)</p>
          <DollarDisclaimer>Prices are shown in USD</DollarDisclaimer>
        </CompareMobileHeader>
        <ComparisonRow
          firstRow
          footnote={1}
          name='tastytrade'
          data={`${'$' + get(ironCondor, 'tastyworks')}`}
          tableType='options'
        />
        <ComparisonRow
          footnote={2}
          name='E*TRADE'
          data={`${'$' + get(ironCondor, 'eTrade')}`}
          tableType='options'
        />
        <ComparisonRow
          footnote={2}
          name='Interactive Brokers'
          data={`${'$' + get(ironCondor, 'interactive')}`}
          tableType='options'
        />
        <ComparisonRow
          footnote={2}
          name='Saxo Markets'
          data={`${'$' + get(ironCondor, 'saxo')}`}
          tableType='options'
        />
        <ComparisonRow
          footnote={2}
          name='Schwab'
          data={`${'$' + get(ironCondor, 'schwab')}`}
          tableType='options'
        />

        <CompareMobileHeader $australia>
          <DollarDisclaimer>Prices are shown in AUD</DollarDisclaimer>
        </CompareMobileHeader>
        <ComparisonRow
          commsec={true}
          footnote={3}
          name='Commsec'
          data={<OneLotCommsec>Price per contract start at $54.60 (Up to and including $10,000) <Variance>OR</Variance> 0.54% (Over $10,000)<sup>5</sup> + ETO contract fees (per contract)<sup>6</sup></OneLotCommsec>}
          tableType='options'
        />
        <ComparisonRow
          commsec={true}
          footnote={7}
          name='Westpac'
          data={<OneLotCommsec>Price per contract start at $59.90 <Variance>OR</Variance> 0.55%<sup>5</sup> + ETO contract fees (per contract)<sup>8</sup></OneLotCommsec>}
          tableType='options'
        />
      </CompareMobileSection>
    </ComparisonTableMobile>
  );
}

export default MobileTable;
