import styled, { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';

const StyledPricing = styled.p`
  &&& {
    position: relative;
    font-family: ${prop('theme.fonts.condensed')};
    font-size: 3.125em;
    font-weight: 700;
    width: fit-content;
    margin: 0 auto;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 4em;
    }

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      font-size: 2rem;
    }

    span {
      font-family: ${prop('theme.fonts.default')};
      font-weight: normal;
      display: block;
      font-size: 0.75rem;
      margin-top: -1em;

      @media (min-width: ${props => props.theme.breakpoints.md}) {
        font-size: 1rem;
      }
    }

    ${ifProp('strikethrough', css`
      text-decoration: line-through solid
      ${props => props.theme.colors.primaryRed};
    `)}
  }
`;

export default StyledPricing;
