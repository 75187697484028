import styled from 'styled-components';
import { prop } from 'styled-tools';

const ComparisonTableDesktop = styled.table`
  display: none;
  margin-bottom: 1.5em;
  width: 100%;

  @media (min-width: ${prop('theme.breakpoints.md')}) {
    display: table;
  }

  &:last-child {
    margin-bottom: 1em;
  }

  thead {
    th {
      font-size: 0.875rem;
      line-height: 1rem;
    }
  }

  th {
    font-family: ${prop('theme.fonts.default')};
    text-align: center;
    padding-bottom: 1em;

    &.dollar-disclaimer {
      font-style: italic;
      padding-bottom: 0.125rem;
    }

    span {
      display: block;
      font-weight: normal;
    }
  }

  td {
    border: 0.0625rem solid ${prop('theme.colors.primaryBlack')};
    font-family: ${prop('theme.fonts.default')};
    font-size: 0.9375rem;
    padding: 0.75em;

    &:first-child {
      border-left: none;
      width: 12rem;

      @media (min-width: ${prop('theme.breakpoints.xl')}) {
        width: 15rem;
      }

      &.first-shares-cell {
        width: 16rem;
      }
    }

    &:not(:first-child) {
      text-align: center;
    }

    &:nth-child(2) {
      width: 7.75rem;

      @media (min-width: ${prop('theme.breakpoints.xl')}) {
        width: 17.125rem;
      }
    }

    img {
      max-width: 6.25rem;
    }
  }

  tr {
    &.tasty {
      td {
        &:first-child {
          border-top: none;
        }

        &:not(:first-child) {
          background: ${prop('theme.colors.primaryRed')};
          color: ${prop('theme.colors.white')};
          font-weight: 700;
        }
      }
    }
  }

  sup {
    padding-left: 0.25rem;
  }
`;

export default ComparisonTableDesktop;
