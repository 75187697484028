export default {
  oneLot: {
    puts: {
      lot: '1',
      contracts: '2',
      tastyworks: '1.00',
      eTrade: '1.30',
      interactive: '2.00',
      saxo: '6.00',
      schwab: '1.30',
    },
    vertical: {
      lot: '1',
      contracts: '4',
      tastyworks: '2.00',
      eTrade: '2.60',
      interactive: '2.80',
      saxo: '12.00',
      schwab: '2.60',
    },
    ironCondor: {
      lot: '1',
      contracts: '8',
      tastyworks: '4.00',
      eTrade: '5.20',
      interactive: '5.60',
      saxo: '24.00',
      schwab: '5.20',
    }
  },
  tenLot: {
    puts: {
      lot: '10',
      contracts: '20',
      tastyworks: '10.00',
      eTrade: '13.00',
      interactive: '14.00',
      saxo: '60.00',
      schwab: '13.00',
    },
    vertical: {
      lot: '10',
      contracts: '40',
      tastyworks: '20.00',
      eTrade: '26.00',
      interactive: '28.00',
      saxo: '120.00',
      schwab: '26.00',
    },
    ironCondor: {
      lot: '10',
      contracts: '80',
      tastyworks: '40.00',
      eTrade: '52.00',
      interactive: '56.00',
      saxo: '240.00',
      schwab: '52.00',
    }
  },
  twentyFiveLot: {
    puts: {
      lot: '25',
      contracts: '50',
      tastyworks: '10.00',
      eTrade: '32.50',
      interactive: '35.00',
      saxo: '150.00',
      schwab: '32.50',
    },
    vertical: {
      lot: '25',
      contracts: '100',
      tastyworks: '20.00',
      eTrade: '65.00',
      interactive: '70.00',
      saxo: '300.00',
      schwab: '65.00',
    },
    ironCondor: {
      lot: '25',
      contracts: '200',
      tastyworks: '40.00',
      eTrade: '130.00',
      interactive: '140.00',
      saxo: '600.00',
      schwab: '130.00',
    }
  },
  fiftyLot: {
    puts: {
      lot: '50',
      contracts: '100',
      tastyworks: '10.00',
      eTrade: '65.00',
      interactive: '70.00',
      saxo: '300.00',
      schwab: '65.00',
    },
    vertical: {
      lot: '50',
      contracts: '200',
      tastyworks: '20.00',
      eTrade: '130.00',
      interactive: '140.00',
      saxo: '600.00',
      schwab: '130.00',
    },
    ironCondor: {
      lot: '50',
      contracts: '400',
      tastyworks: '40.00',
      eTrade: '260.00',
      interactive: '280.00',
      saxo: '1,200.00',
      schwab: '260.00',
    }
  },
  oneHundredLot: {
    puts: {
      lot: '100',
      contracts: '200',
      tastyworks: '10.00',
      eTrade: '130.00',
      interactive: '140.00',
      saxo: '600.00',
      schwab: '130.00',
    },
    vertical: {
      lot: '100',
      contracts: '400',
      tastyworks: '20.00',
      eTrade: '260.00',
      interactive: '280.00',
      saxo: '1,200.00',
      schwab: '260.00',
    },
    ironCondor: {
      lot: '100',
      contracts: '800',
      tastyworks: '40.00',
      eTrade: '520.00',
      interactive: '560.00',
      saxo: '2,400.00',
      schwab: '520.00',
    }
  },
  twoHundredLot: {
    puts: {
      lot: '200',
      contracts: '400',
      tastyworks: '10.00',
      eTrade: '260.00',
      interactive: '280.00',
      saxo: '1,200.00',
      schwab: '260.00',
    },
    vertical: {
      lot: '200',
      contracts: '800',
      tastyworks: '20.00',
      eTrade: '520.00',
      interactive: '560.00',
      saxo: '2,400.00',
      schwab: '520.00',
    },
    ironCondor: {
      lot: '200',
      contracts: '1600',
      tastyworks: '40.00',
      eTrade: '1,040.00',
      interactive: '1,120.00',
      saxo: '4,800.00',
      schwab: '1,040.00',
    }
  },
  fiveHundredLot: {
    puts: {
      lot: '500',
      contracts: '1000',
      tastyworks: '10.00',
      eTrade: '650.00',
      interactive: '700.00',
      saxo: '3,000.00',
      schwab: '650.00',
    },
    vertical: {
      lot: '500',
      contracts: '2000',
      tastyworks: '20.00',
      eTrade: '1,300.00',
      interactive: '1,400.00',
      saxo: '6,000.00',
      schwab: '1300.00',
    },
    ironCondor: {
      lot: '500',
      contracts: '4000',
      tastyworks: '40.00',
      eTrade: '2,600.00',
      interactive: '2,800.00',
      saxo: '12,000.00',
      schwab: '2,600.00',
    }
  }
};
