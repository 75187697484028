import styled from 'styled-components';
import { prop } from 'styled-tools';

const StyledPricingTable = styled.table`
  border: 1px solid ${props => props.theme.colors.white};
  width: 100%;
  border-collapse: separate;
  margin-bottom: 1em;

  th {
    font-family: ${prop('theme.fonts.default')};
    font-weight: 700;
    text-align: center;
    color: ${props => props.theme.colors.primaryBlack};
    font-size: 0.875rem;
    padding-bottom: 0.625rem;

    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      font-size: 1rem;
    }

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 1.125em;
    }
  }

  td {
    font-family: ${prop('theme.fonts.default')};
    background-color: ${prop('theme.colors.primaryBlack')};
    color: ${prop('theme.colors.white')};
    text-align: right;
    padding: 0.5em;
    vertical-align: top;

    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      padding: 1em;
    }

    &.zero-cell {
      background-color: ${props => props.theme.colors.primaryRed};
    }

    &.struckthrough {
      background-color: ${props => props.theme.colors.secondaryOffWhite};
      color: ${props => props.theme.colors.primaryBlack};
    }

    &:first-child {
      background-color: ${props => props.theme.colors.primaryOffWhite};
      color: ${prop('theme.colors.primaryBlack')};
      font-size: 0.875rem;
      font-weight: 700;
      text-align: center;
      vertical-align: middle;

      @media (min-width: ${props => props.theme.breakpoints.sm}) {
        font-size: 1rem;
      }

      @media (min-width: ${props => props.theme.breakpoints.md}) {
        font-size: 1.125em;
      }

      span {
        font-size: 0.75rem;
        font-weight: normal;
        display: block;

        @media (min-width: ${props => props.theme.breakpoints.md}) {
          font-size: 1rem;
        }
      }
    }
  }
`;

export default StyledPricingTable;