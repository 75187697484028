import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components'
import { prop, ifProp } from 'styled-tools';

const StyledRow = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.darkGray};
  display: flex;
  font-family: ${props => props.theme.fonts.default};
  height: 3.125rem;
  justify-content: space-between;
  padding: 0.625rem 0.625rem 0 0.625rem;

  ${ifProp(
    'firstRow',
    css`
      background: ${prop('theme.colors.lighterGray')};
      > div {
        &:last-child {
          color: ${prop('theme.colors.primaryRed')};
        }
      }
    `
  )}

  ${ifProp('$commsec', css`
    align-items: center;
    height: auto;
  `)}

  ${ifProp({ $tableType: 'shares'}, css`
    align-items: center;
    height: auto;
    min-height: 3.125rem;
    padding: 0 0.625rem;
  `)}
`;

const Brokerage = styled.div`
  font-size: 1rem;
  text-align: left;

  sup {
    padding-left: 0.25rem;
  }
`;

const Pricing = styled.div`
  font-size: 1.125rem;
  font-weight: 600;

  ${ifProp('$commsec', css`
    font-size: 0.9375rem;
    font-weight: 400;
    max-width: 70%;
    text-align: right;
  `)}

  ${ifProp({ $tableType: 'shares'}, css`
    font-size: 1rem;
    max-width: 50%;
    text-align: right;
  `)}
`;

const propTypes = {
  commsec: PropTypes.bool,
  data: PropTypes.string.isRequired,
  firstRow: PropTypes.bool,
  footnote: PropTypes.number,
  name: PropTypes.string.isRequired,
  tableType: PropTypes.string.isRequired,
};

function ComparisonRow ({ commsec, data, firstRow, footnote, name, tableType }) {
  return (
    <StyledRow $commsec={commsec} firstRow={firstRow} $tableType={tableType}>
      <Brokerage>
        {name}
        {footnote && (
          <sup>{footnote}</sup>
        )}
      </Brokerage>
      <Pricing $commsec={commsec} $tableType={tableType}>{data}</Pricing>
    </StyledRow>
  )
}

ComparisonRow.propTypes = propTypes;

export default ComparisonRow;
