import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComparisonTableDesktop from '../styled/ComparisonTableDesktop';
import logo from 'images/tastytrade-logo-black.png';

const CommsecRow = styled.tr`
  td {
    &:not(:first-child) {
      padding: 0.25em;
    }
  }
`;

const Investor = styled.td`
  text-align: right;
`;

DesktopTable.propTypes = {
  data: PropTypes.object,
  ironCondor: PropTypes.object,
  oneLot: PropTypes.string,
  puts: PropTypes.object,
  tableHeader: PropTypes.string,
  vertical: PropTypes.object,
};

function DesktopTable({ data, ironCondor, oneLot, puts, tableHeader, vertical }) {
  return (
    <>
      <ComparisonTableDesktop>
        <thead>
          <tr>
            <th />
            <th>
              {get(puts, 'lot')}
              {' '}
              {get(puts, 'lot') == 1 ? 'Put/Call' : 'Puts/Calls'}
              <span>({get(puts, 'contracts')} total contracts)</span>
            </th>
            <th>
              {get(vertical, 'lot')}
              {' '}
              {get(vertical, 'lot') == 1 ? 'Vertical' : 'Verticals'}
              <span>({get(vertical, 'contracts')} total contracts)</span>
            </th>
            <th>
              {get(ironCondor, 'lot')}
              {' '}
              {get(ironCondor, 'lot') == 1 ? 'Iron Condor' : 'Iron Condors'}
              <span>({get(ironCondor, 'contracts')} total contracts)</span>
            </th>
          </tr>
          <tr>
            <th />
            <th className='dollar-disclaimer' colspan='3'>
              Prices are shown in USD
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className='tasty'>
            <Investor>
              <img src={logo} alt='tastylive' />
              <sup>1</sup>
            </Investor>
            <td>${get(puts, 'tastyworks')}</td>
            <td>${get(vertical, 'tastyworks')}</td>
            <td>${get(ironCondor, 'tastyworks')}</td>
          </tr>
          <tr>
            <Investor>
              E*TRADE
              <sup>2</sup>
            </Investor>
            <td>${get(puts, 'eTrade')}</td>
            <td>${get(vertical, 'eTrade')}</td>
            <td>${get(ironCondor, 'eTrade')}</td>
          </tr>
          <tr>
            <Investor>
              Interactive Brokers
              <sup>2</sup>
            </Investor>
            <td>${get(puts, 'interactive')}</td>
            <td>${get(vertical, 'interactive')}</td>
            <td>${get(ironCondor, 'interactive')}</td>
          </tr>
          <tr>
            <Investor>
              Saxo Markets
              <sup>2</sup>
            </Investor>
            <td>${get(puts, 'saxo')}</td>
            <td>${get(vertical, 'saxo')}</td>
            <td>${get(ironCondor, 'saxo')}</td>
          </tr>
          <tr>
            <Investor>
              Schwab
              <sup>2</sup>
            </Investor>
            <td>${get(puts, 'schwab')}</td>
            <td>${get(vertical, 'schwab')}</td>
            <td>${get(ironCondor, 'schwab')}</td>
          </tr>
        </tbody>
      </ComparisonTableDesktop>
      <ComparisonTableDesktop>
        <thead>
          <tr>
            <th />
            <th className='dollar-disclaimer' colspan='3'>
              Prices are shown in AUD
            </th>
          </tr>
        </thead>
        <tbody>
          <CommsecRow>
            <Investor>
              Commsec
              <sup>3</sup>
            </Investor>
            <td>Price per contract start at $34.95 (Up to and including $10,000) OR 0.35% (above $10,000)<sup>4</sup> + ETO contract fees (per contract)<sup>6</sup></td>
            <td>Price per contract start at $54.60 (Up to and including $10,000) OR 0.54% (Over $10,000)<sup>5</sup> + ETO contract fees (per contract)<sup>6</sup></td>
          </CommsecRow>
          <CommsecRow>
            <Investor>
              Westpac
              <sup>7</sup>
            </Investor>
            <td>Price per contract start at $38.95 OR 0.35%<sup>4</sup> + ETO contract fees (per contract)<sup>8</sup></td>
            <td>Price per contract start at $59.90 OR 0.55%<sup>5</sup> + ETO contract fees (per contract)<sup>8</sup></td>
          </CommsecRow>
        </tbody>
      </ComparisonTableDesktop>
    </>
  );
}

export default DesktopTable;
