import React, { useEffect } from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import { Link } from 'gatsby';
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor';
import ReadyToTrade, { ReadyToTradeSection } from 'components/ReadyToTrade';
import Layout from 'components/Layout';
import Headline from 'components/Headline';
import SectionsWrapper from 'components/SectionsWrapper';
import Hero from 'components/Hero';
import Section from 'components/Section';
import SmallPrint from 'components/SmallPrint';
import SectionDivider from 'components/SectionDivider';
import TeamSection from 'components/TeamSection';
import ApplesToApples from 'components/ApplesToApples';
import pricingHero from 'images/hero/image-pricing-hero.png';
import businessTeam from 'images/pricing-business-team.png';
import zeroHashLogo from 'images/ZeroHash_Logo_3_ZH LOGO black.svg';
import StyledPricingTable from 'components/StyledPricingTable';
import { useLayoutContext } from 'context/LayoutContext';
import StyledPricing from 'components/StyledPricing';
import SEO from 'components/SEO';
import TitleHeader from 'components/TitleHeader';

const PricingSection = styled(Section)`
  padding-left: 1.25rem;
  padding-right: 1.25rem;

  @media (min-width: ${prop('theme.breakpoints.md')}) {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    padding-left: 10.125rem;
    padding-right: 10.125rem;
  }

  .scroll-links {
    font-weight: 700;
    text-decoration: none;

    &:hover,
    &:active {
      color: ${prop('theme.colors.primaryRed')};
      text-decoration: none;
    }
  }
`;

const PricingSectionsWrapper = styled(SectionsWrapper)`
  ${Headline} {
    font-size: 3em;
    padding-bottom: 0;
    text-align: left;
  }

  ${PricingSection} {
    &:not(:first-child) {
      padding-bottom: 2rem;
    }

    &:not(${ReadyToTradeSection}) {
      padding-bottom: 2rem;
    }
  }

  ${SectionDivider} {
    padding-top: 4rem;
  }

  &.container {
    padding: 0;
  }
`;

const PricingDisclaimer = styled(SmallPrint)`
  & {
    font-size: 0.75rem;
  }
`;

const HeaderText = styled.h2`
   color: ${props => props.theme.colors.black};
   text-transform: uppercase;
   font-size: 3em;
   padding: 3.75rem 0px 0px 0px;
   margin-bottom: 0;
`;

const PricingLink = styled(Link)`
  font-family: ${prop('theme.fonts.default')};
  font-size: 1.125em;
  color: ${props => props.theme.colors.black};
  text-decoration: underline;
  margin-bottom: 0.25rem;
  display: block;
`;

const LogoImage = styled.img`
  height: 1rem;
  margin-left: -0.5rem;
  margin-right: -0.3rem;
`;

function PricingPage({ showPromoBar }) {
  const { state: layoutContextState } = useLayoutContext();
  const { showAlert } = layoutContextState;

  useEffect(() => {
    configureAnchors({offset: -80, scrollDuration: 600});
  })

  return (
    <Layout>
      <SEO
        title='Pricing'
        description='Competitive options commissions for the active trader. $1.00/Contract. Free To Close. No ticket charge.'
        canonicalHref='https://tastytrade.com/pricing'
      />
      <main>
        <Hero
          heroImage={pricingHero}
          $showAlert={showAlert}
          showPromoBar={showPromoBar}
        >
          <h1>Pricing</h1>
          <h2>Freedom to trade more</h2>
        </Hero>
        <PricingSectionsWrapper>
          <PricingSection>
            <HeaderText className="text-center">
              Low Commissions
            </HeaderText>
          </PricingSection>
          <PricingSection>
            <p>
              We all want low commissions. Lower commissions mean more freedom to
              place more trades more efficiently. That’s why we offer a great rate to open
              trades and an even better one to close them. It’s also why we cap your commissions
              at $10 per leg on equity option trades and $10 per opening and closing cryptocurrency trades.
              ** See how our pricing truly {' '}
              <a className='scroll-links' href='#applesToApples'>
                compares
              </a> {' '}
              against other brokers.
            </p>
          </PricingSection>
          <PricingSection>
            <TitleHeader>Commissions</TitleHeader>
            <PricingDisclaimer>
              * All prices are shown in U.S. Dollars.
            </PricingDisclaimer>
            <StyledPricingTable>
              <thead>
                <tr>
                  <th />
                  <th>Opening Commission*</th>
                  <th>Closing Commission*</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Options on
                    <br />
                    Stock &amp; ETFs
                    <span>($10 max per leg)</span>
                  </td>
                  <td>
                    <StyledPricing>
                      $1.00
                      <span>
                        per contract
                        <br />
                        ($10 max per leg)
                      </span>
                    </StyledPricing>
                  </td>
                  <td className='zero-cell'>
                    <StyledPricing>$0</StyledPricing>
                  </td>
                </tr>
                <tr>
                  <td>
                    Options on
                    <br />
                    Futures
                  </td>
                  <td>
                    <StyledPricing>
                      $2.50
                      <span>
                        per contract
                      </span>
                    </StyledPricing>
                  </td>
                  <td className='zero-cell'>
                    <StyledPricing>$0</StyledPricing>
                  </td>
                </tr>
                <tr>
                  <td>
                    Options on
                    <br />
                    Micro Futures
                  </td>
                  <td>
                    <StyledPricing>
                      $1.50
                      <span>per contract</span>
                    </StyledPricing>
                  </td>
                  <td className='zero-cell'>
                    <StyledPricing>$0</StyledPricing>
                  </td>
                </tr>
                <tr>
                  <td>Stock &amp; ETFs</td>
                  <td className='zero-cell'>
                    <StyledPricing>
                      $0
                      <span>unlimited shares</span>
                    </StyledPricing>
                  </td>
                  <td className='zero-cell'>
                    <StyledPricing>$0</StyledPricing>
                  </td>
                </tr>
                <tr>
                  <td>Futures</td>
                  <td>
                    <StyledPricing>
                      $1.25
                      <span>per contract</span>
                    </StyledPricing>
                  </td>
                  <td>
                    <StyledPricing>
                      $1.25
                      <span>per contract</span>
                    </StyledPricing>
                  </td>
                </tr>
                <tr>
                  <td>Micro Futures</td>
                  <td>
                    <StyledPricing>
                      $0.85
                      <span>per contract</span>
                    </StyledPricing>
                  </td>
                  <td>
                    <StyledPricing>
                      $0.85
                      <span>per contract</span>
                    </StyledPricing>
                  </td>
                </tr>
                <tr>
                  <td>Smalls Futures</td>
                  <td>
                    <StyledPricing>
                      $0.25
                      <span>per contract</span>
                    </StyledPricing>
                  </td>
                  <td>
                    <StyledPricing>
                      $0.25
                      <span>per contract</span>
                    </StyledPricing>
                  </td>
                </tr>
                <tr>
                  <td>
                    Cryptocurrency
                    <span>($10 max)</span>
                  </td>
                  <td>
                    <StyledPricing>
                      1%
                      <span>of total crypto purchase</span>
                    </StyledPricing>
                  </td>
                  <td>
                    <StyledPricing>
                      1%
                      <span>of total crypto sale</span>
                    </StyledPricing>
                  </td>
                </tr>
              </tbody>
            </StyledPricingTable>
            <SmallPrint>
              * Applicable exchange, clearing, and regulatory fees still
              apply to all opening and closing trades except for cryptocurrency
              orders which are not subject to exchange, clearing, and regulatory fees.
            </SmallPrint>
            <PricingLink to='/commissions-and-fees/'>
              View Pricing and Fees Detail
            </PricingLink>
          </PricingSection>
          <PricingSection>
            <ScrollableAnchor id='cappedCommission'>
              <SectionDivider />
            </ScrollableAnchor>
            <TitleHeader>Capped Commissions</TitleHeader>
            <p>
              Our commissions are capped at $10 per leg for all opening listed equity
              option trades, and $10 for all opening and closing cryptocurrency trades.**
            </p>
            <p>Here’s how that can really work for you!</p>
            <StyledPricingTable>
              <thead>
                <tr>
                  <th />
                  <th colSpan='2'>Opening Commission**</th>
                  <th>To Close</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    20 Puts / Calls
                    <span>single leg</span>
                  </td>
                  <td className='struckthrough'>
                    <StyledPricing strikethrough>$20</StyledPricing>
                  </td>
                  <td>
                    <StyledPricing>$10</StyledPricing>
                  </td>
                  <td className='zero-cell'>
                    <StyledPricing>$0</StyledPricing>
                  </td>
                </tr>
                <tr>
                  <td>
                    20 Verticals
                    <span>two legs</span>
                  </td>
                  <td className='struckthrough'>
                    <StyledPricing strikethrough>$40</StyledPricing>
                  </td>
                  <td>
                    <StyledPricing>$20</StyledPricing>
                  </td>
                  <td className='zero-cell'>
                    <StyledPricing>$0</StyledPricing>
                  </td>
                </tr>
                <tr>
                  <td>
                    20 Iron Condors
                    <span>four legs</span>
                  </td>
                  <td className='struckthrough'>
                    <StyledPricing strikethrough>$80</StyledPricing>
                  </td>
                  <td>
                    <StyledPricing>$40</StyledPricing>
                  </td>
                  <td className='zero-cell'>
                    <StyledPricing>$0</StyledPricing>
                  </td>
                </tr>
                <tr>
                  <td>
                    $2,000 crypto
                  </td>
                  <td className='struckthrough'>
                    <StyledPricing strikethrough>$20</StyledPricing>
                  </td>
                  <td>
                    <StyledPricing>$10</StyledPricing>
                  </td>
                  <td className='zero-cell'>
                    <StyledPricing>$10</StyledPricing>
                  </td>
                </tr>
                <tr>
                  <td>
                    $4,000 crypto
                  </td>
                  <td className='struckthrough'>
                    <StyledPricing strikethrough>$40</StyledPricing>
                  </td>
                  <td>
                    <StyledPricing>$10</StyledPricing>
                  </td>
                  <td className='zero-cell'>
                    <StyledPricing>$10</StyledPricing>
                  </td>
                </tr>
                <tr>
                  <td>
                    $8,000 crypto
                  </td>
                  <td className='struckthrough'>
                    <StyledPricing strikethrough>$80</StyledPricing>
                  </td>
                  <td>
                    <StyledPricing>$10</StyledPricing>
                  </td>
                  <td className='zero-cell'>
                    <StyledPricing>$10</StyledPricing>
                  </td>
                </tr>
              </tbody>
            </StyledPricingTable>
            <SmallPrint>
              ** Exchange, clearing, and regulatory fees still apply for all opening and
              closing equity options trades. All futures options and the following index
              products are excluded from this offer: SPX, RUT, VIX, OEX, XEO, DJX, and NDX.
              Applicable opening and closing commissions apply to all cryptocurrency trades.
            </SmallPrint>
            <SmallPrint>
              <LogoImage src={zeroHashLogo} alt='zero-hash'/>
              Cryptocurrency trading provided by Zero Hash Liquidity Services LLC and
              cryptocurrency custody provided by Zero Hash LLC.
            </SmallPrint>
          </PricingSection>
          <PricingSection>
            <ScrollableAnchor id='applesToApples'>
              <SectionDivider />
            </ScrollableAnchor>
            <ApplesToApples />
          </PricingSection>
          <ReadyToTrade />
          <TeamSection
            dark
            tagline='from the business team!'
            imageSrc={businessTeam}
            imageAlt='Business Team'
          />
        </PricingSectionsWrapper>
      </main>
    </Layout>
  )
}

export default PricingPage;
