import React, { useState } from 'react';
import get from 'lodash.get';
import { prop } from 'styled-tools';
import styled from 'styled-components';
import MobileTable from './MobileTable';
import DesktopTable from './DesktopTable';
import Disclaimers from './Disclaimers';
import COMPARE_TABLE_DATA from 'utils/compareTableData';

const TabNav = styled.nav`
  width: 100%;
  margin-bottom: 1.25rem;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-weight: 700;
    font-size: 1.125em;
    display: flex;
    justify-content: stretch;
    border-bottom: 0.0625rem solid ${prop('theme.colors.primaryBlack')};

    li {
      cursor: pointer;
      flex: 1 0 auto;
      padding: 0.625rem 0;
      text-align: center;

      &.active {
        background: ${prop('theme.colors.primaryBlack')};
        color: ${prop('theme.colors.white')};
      }

      .lot-label {
        @media (max-width: ${props => props.theme.breakpoints.md}) {
          display: none;
        }
      }
    }
  }
`;

function OptionsTable() {
  const data = COMPARE_TABLE_DATA;
  const [tableHeader, setTableHeader] = useState('fiftyLot');

  const oneLot = tableHeader === 'oneLot';
  const puts = get(data[`${tableHeader}`], 'puts');
  const vertical = get(data[`${tableHeader}`], 'vertical');
  const ironCondor = get(data[`${tableHeader}`], 'ironCondor');

  const renderTableData = (event) => {
    const { param } = event.currentTarget.dataset;
    setTableHeader(param);
  }

  return (
    <>
      <TabNav aria-label='Options Table'>
        <ul>
          <li
            className={oneLot ? 'active' : ''}
            data-param='oneLot'
            onClick={renderTableData}
          >
            1 <span className='lot-label'>LOT</span>
          </li>
          <li
            className={tableHeader === 'tenLot' ? 'active' : ''}
            data-param='tenLot'
            onClick={renderTableData}
          >
            10 <span className='lot-label'>LOT</span>
          </li>
          <li
            className={tableHeader === 'twentyFiveLot' ? 'active' : ''}
            data-param='twentyFiveLot'
            onClick={renderTableData}
          >
            25 <span className='lot-label'>LOT</span>
          </li>
          <li
            className={tableHeader === 'fiftyLot' ? 'active' : ''}
            data-param='fiftyLot'
            onClick={renderTableData}
          >
            50 <span className='lot-label'>LOT</span>
          </li>
          <li
            className={tableHeader === 'oneHundredLot' ? 'active' : ''}
            data-param='oneHundredLot'
            onClick={renderTableData}
          >
            100 <span className='lot-label'>LOT</span>
          </li>
          <li
            className={tableHeader === 'twoHundredLot' ? 'active' : ''}
            data-param='twoHundredLot'
            onClick={renderTableData}
          >
            200 <span className='lot-label'>LOT</span>
          </li>
          <li
            className={tableHeader === 'fiveHundredLot' ? 'active' : ''}
            data-param='fiveHundredLot'
            onClick={renderTableData}
          >
            500 <span className='lot-label'>LOT</span>
          </li>
        </ul>
      </TabNav>
      <DesktopTable
        data={data}
        puts={puts}
        oneLot={oneLot}
        vertical={vertical}
        ironCondor={ironCondor}
        tableHeader={tableHeader}
      />
      <MobileTable
        data={data}
        puts={puts}
        oneLot={oneLot}
        vertical={vertical}
        ironCondor={ironCondor}
        tableHeader={tableHeader}
      />
      <Disclaimers />
    </>
  );
}

export default OptionsTable;
